import { createSlice, current } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createBonusAmount, deleteBonusAmount, deleteManyBonusAmount, getAllBonusAmount, getOneBonusAmount, updateBonusAmount, } from './actions';
var initialState = {
    bonusAmounts: {
        docs: [],
        meta: {},
    },
    bonusAmount: {},
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'bonusAmounts',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(createBonusAmount.pending, function (state) {
            state.status = IStatus.LOADING;
        });
        builder.addCase(createBonusAmount.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.bonusAmounts = payload.data;
        });
        builder.addCase(createBonusAmount.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        builder
            .addCase(getAllBonusAmount.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllBonusAmount.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.bonusAmounts = payload;
        })
            .addCase(getAllBonusAmount.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteBonusAmount.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteBonusAmount.fulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.bonusAmounts.docs = (_b = current(state.bonusAmounts).docs) === null || _b === void 0 ? void 0 : _b.filter(function (elt) { return (elt === null || elt === void 0 ? void 0 : elt._id) !== payload.bounsAmountId; });
        })
            .addCase(deleteBonusAmount.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getOneBonusAmount.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneBonusAmount.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.bonusAmount = payload.data;
        })
            .addCase(getOneBonusAmount.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(updateBonusAmount.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateBonusAmount.fulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.bonusAmounts.docs = (_b = current(state.bonusAmounts).docs) === null || _b === void 0 ? void 0 : _b.map(function (elt) { var _a; return (elt === null || elt === void 0 ? void 0 : elt._id) === ((_a = payload.data) === null || _a === void 0 ? void 0 : _a._id) ? payload.data : elt; });
        })
            .addCase(updateBonusAmount.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteManyBonusAmount.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteManyBonusAmount.fulfilled, function (state) {
            state.status = IStatus.SUCCEEDED;
        })
            .addCase(deleteManyBonusAmount.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var actions = slice.actions;
export default slice.reducer;
